import { css } from 'astroturf';
import React from 'react';
import { Download } from 'react-bytesize-icons';
import UAParser from 'ua-parser-js';

import CTA from '../components/CTA-small';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import releaseData from '../data/releaseData';
import Layout from '../layout';

const version = releaseData[0].fullVersion;

const getPlatform = () => {
  const result = new UAParser().getResult();
  const platformName = result.os.name;
  let type;
  let arch = result.cpu?.architecture || 'amd64';

  switch (platformName) {
    case 'Windows':
      type = 'exe';
      arch = 'universal';
      break;
    case 'Ubuntu':
      type = 'deb';
      break;
    case 'Linux':
      type = 'appimage';
      break;
    case 'Mac OS':
      type = 'dmg';
      arch = 'universal';
      break;
    default:
      type = 'exe';
      break;
  }

  return { type, arch };
};

const styles = css`
  .downloadPage {
    text-align: center;
    padding-bottom: 6rem;

    & p {
      padding: 0 2rem;
    }

    & .form {
      margin: 0.5rem auto 0;
      max-width: 18rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      & select {
        border: 0;
        border-radius: 0.25rem;
        padding: 0.75rem 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        flex: 1;
        background: transparent;
        outline: 2px dashed transparent;
      }

      & a {
        flex: 1;
        text-align: center;
        border: 0;
        color: #fff;
        background: #67b26f;
        border-radius: 5rem;
        padding: 0.75rem 0.5rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        transition: 0.25s ease-out all;

        &:focus-visible {
          outline: 2px dashed #506478;
        }

        &:hocus {
          background: #0a1530;
          &:after {
            display: none;
          }
        }

        & svg {
          margin-left: 0.5rem;
          opacity: 0.8;
          vertical-align: middle;
        }
      }

      @media (min-width: 30rem) {
        flex-direction: row;
        max-width: 30rem;
        justify-content: center;
        background: rgb(76 162 205/ 0.075);
        box-shadow: inset 0px 2px 1px rgba(0, 10, 20, 0.2);
        padding: 0.5rem;
        border-radius: 5rem;
        margin-bottom: 0.5rem;

        & select {
          border: 0;
          background-color: none;
          margin: 0 1rem 0 0;
          padding-left: 1rem;
        }

        & a {
          margin: 0;
          border-radius: 5rem;
        }
      }
    }

    & .additional {
      display: block;
      max-width: 40rem;
      margin: 2rem auto;
    }
    & .topline {
      margin-bottom: 2rem;
    }
  }

  .helpers {
    padding: 6rem 1rem;
    max-width: 48rem;
    margin: 2rem auto 0;
    position: relative;
    text-align: center;
    scroll-margin-top: 100px;

    & p {
      margin: 0.5rem 0;
      padding: 0 1rem;
      line-height: 28px;
    }

    & a {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 8px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0.5rem 0.5rem;
      &:hocus {
        background: transparent;
        color: #107db5;
      }
    }
  }

  @media (min-width: 60rem) {
    .centerer {
      margin-top: -4rem;
    }
  }

  .alternate {
    max-width: 30rem;
    padding: 1rem;
    background: rgb(76 162 205/ 0.075);
    box-shadow: inset 0px 2px 1px rgba(0, 10, 20, 0.2);
    border-radius: 1rem;
    margin: 2rem auto;
    line-height: 1.8;

    & code {
      user-select: all;
      padding: 4px;
      border: 1px solid rgba(0, 10, 20, 0.2);
      border-radius: 4px;
      background: #fff;
    }
  }
`;

const downloads = [
  {
    version: 'exe',
    prettyArch: 'universal',
    arch: 'amd64 and arm64',
    name: 'Windows',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/Polypane-Setup-${version}.exe`,
    text: 'Compatible with Windows 10 and 11, min 4GB ram.',
  },
  {
    version: 'dmg',
    prettyArch: 'universal',
    arch: 'Intel and Apple Silicon (M1, M2, M3 etc) macs',
    name: 'Mac OS',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/Polypane-${version}-universal.dmg`,
    text: 'For Intel and Apple Silicon. Tested with macOS Sonoma, min 4GB ram',
    alternate: (
      <>
        You can also install Polypane through <a href="https://formulae.brew.sh/cask/polypane">Homebrew</a> with{' '}
        <code>brew install --cask polypane</code>.
      </>
    ),
  },
  {
    version: 'deb',
    arch: 'amd64',
    name: 'Ubuntu Deb',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/polypane_${version}_amd64.deb`,
    text: 'Compatible with Ubuntu 18.04+, min 4GB ram.',
  },
  {
    version: 'deb',
    arch: 'arm64',
    name: 'Ubuntu Deb',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/polypane_${version}_arm64.deb`,
    text: 'Compatible with Ubuntu 18.04+, min 4GB ram.',
  },
  {
    version: 'appimage',
    arch: 'amd64',
    name: 'Linux AppImage',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/polypane-${version}.AppImage`,
    text: 'Compatible with most Linux OS, min 4GB ram.',
  },
  {
    version: 'appimage',
    arch: 'arm64',
    name: 'Linux AppImage',
    link: `https://github.com/firstversionist/polypane/releases/download/v${version}/polypane-${version}-arm64.AppImage`,
    text: 'Compatible with most Linux OS, min 4GB ram.',
  },
];

class Page extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedPlatform: '',
      link: '',
      helptext: 'For Mac, Windows and Linux',
      arch: 'amd64',
      alternate: undefined,
    };
  }

  componentDidMount() {
    const info = getPlatform();
    const currentPlatform = downloads.findIndex(
      (item) => item.version === info.type && (item.prettyArch || item.arch) === info.arch
    );

    if (currentPlatform > -1) {
      this.setState({
        selectedPlatform: currentPlatform,
        link: downloads[currentPlatform].link,
        helptext: downloads[currentPlatform].text,
        arch: downloads[currentPlatform].arch,
        alternate: downloads[currentPlatform].alternate,
      });
    }
  }

  updateDownload = (e) => {
    const newVersion = parseInt(e.target.value, 10);
    const newPlatform = downloads[newVersion];

    this.setState({
      selectedPlatform: newVersion,
      link: newPlatform.link,
      helptext: newPlatform.text,
      arch: newPlatform.arch,
      alternate: newPlatform.alternate,
    });
  };

  render() {
    const { selectedPlatform, link, helptext, arch, alternate } = this.state;
    return (
      <Layout>
        <SEO title="Download" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Download</h1>
          <h2>Get the latest version of Polypane for your OS</h2>
        </PageHeader>

        <div className={styles.downloadPage}>
          <div className={styles.form}>
            <select value={selectedPlatform} onChange={this.updateDownload}>
              {downloads.map((item, index) => (
                <option value={index} key={item.version}>
                  {item.name} ({item.prettyArch || item.arch})
                </option>
              ))}
            </select>
            <Link to={link} download>
              Download <Download width={24} height={24} />
            </Link>
          </div>

          <p>
            {helptext}
            <br />
            <small>Architecture: {arch}</small>
          </p>

          {alternate ? (
            <div className={styles.alternate}>
              <h4>Alternate installation methods</h4>
              <p>{alternate}</p>
            </div>
          ) : null}

          <div className={styles.helpers} id="extensions">
            <h4>(Optional) install the Polypane browser extension</h4>
            <p>
              With the Polypane browser extension you can send any link or tab to Polypane. The extension is available
              for the following browsers:
            </p>
            <p>
              <Link to="https://chrome.google.com/webstore/detail/polypane-helper/eofbapfmbfmpeplodnehlkkgpkklmapp">
                Chrome
              </Link>
              <Link to="https://addons.mozilla.org/en-US/firefox/addon/polypane-helper/">Firefox</Link>
              <Link to="https://apps.apple.com/us/app/polypane-helper/id1541304901">Safari</Link>
              <Link to="https://microsoftedge.microsoft.com/addons/detail/leojkjmhbidaiacgldgodkagffjhjipn">Edge</Link>
              <Link to="https://addons.opera.com/en/extensions/details/polypane-helper/">Opera</Link>
            </p>
            <p>
              <em>The Chrome extension works in other Chromium-based browsers like Brave and Vivaldi.</em>
            </p>
          </div>

          <div className={styles.centerer}>
            <CTA text="Don't have a Polypane subscription yet? Start your 14 day free trial!" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Page;
